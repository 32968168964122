<template>
  <VWidget content-class="pa-0" v-if="deal.lead">
    <template v-slot:title>{{ $t('labels.leadInformation') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.providerLeadId') }}</v-list-item-subtitle>
            <v-list-item-title>{{ deal.lead.provider_lead_id }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.dealType') }}</v-list-item-subtitle>
            <v-list-item-title>{{ deal.lead.info.type }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.dealPurpose') }}</v-list-item-subtitle>
            <v-list-item-title>{{ deal.lead.info.purpose }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.dealStatus') }}</v-list-item-subtitle>
            <v-list-item-title>{{ deal.lead.info.status }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.dealState') }}</v-list-item-subtitle>
            <v-list-item-title>{{ deal.lead.info.state }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.lenderName') }}</v-list-item-subtitle>
            <v-list-item-title>{{ deal.lead.info.lender }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.insurer') }}</v-list-item-subtitle>
            <v-list-item-title>{{ deal.insurer }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'

export default {
  name: 'VDealLeadInfo',
  components: { VWidget },
  props: {
    deal: Object
  }
}
</script>
