<template>
  <v-list-item v-if="!loading">
    <v-list-item-content>
      <v-list-item-subtitle>{{ $t(`labels.${label}`) }}</v-list-item-subtitle>
      <v-list-item-title>{{ `${broker.display}, ${broker.firm ? broker.firm.display : ''}` }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { NAMESPACE } from '../store'

export default {
  name: 'VBrokerListItem',
  data: () => ({ loading: true }),
  props: {
    label: { type: String, default: 'name' },
    brokerId: { type: [String, Number], required: true }
  },
  computed: {
    ...mapState(NAMESPACE, ['broker'])
  },
  methods: {
    ...mapActions(NAMESPACE, ['getBroker'])
  },
  created() {
    this.getBroker(this.brokerId)
      .then(() => {
        this.loading = false
      })
      .catch((e) => {})
  }
}
</script>
