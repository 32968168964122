<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.state') }}</template>
    <template v-slot:header-actions v-if="!readOnly">
      <v-btn icon small @click="edit">
        <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.status') }}</v-list-item-subtitle>
            <v-list-item-title>{{ $t(`labels.${deal.status}`) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="deal.status === 'cancelled'" />
        <refusal-list-item v-if="deal.status === 'cancelled'" :deal="deal" />
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.ongoingSoldOn') }}</v-list-item-subtitle>
            <v-list-item-title>
              {{ transformDate(deal.meta.dates.ongoing_sold_on) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.finalizedPaidOn') }}</v-list-item-subtitle>
            <v-list-item-title>
              {{ transformDate(deal.meta.dates.finalized_paid_on) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-row no-gutters>
            <v-col>
              <v-checkbox :label="$t('labels.byPhone')" v-model="deal.sold_by_phone" readonly />
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formatDT } from '@argon/app/helpers'
import { VWidget } from '@argon/app/components'
import { NAMESPACE } from '@argon/evo/boards/store'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { RefusalListItem } from '../../../refusals/components'

export default {
  name: 'VIndividualDealState',
  components: { VWidget, RefusalListItem },
  props: {
    deal: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  data: () => ({
    statuses: [{ display: 'Ongoing', id: 1 }],
    refusals: [{ display: 'A decision', id: 1 }]
  }),
  computed: {
    ...mapState(NAMESPACE, ['columns']),
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale'])
  },
  methods: {
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL', tz: this.timezone, locale: this.locale }) : ''
    },
    edit() {
      this.$router.push({
        name: 'nestedDealState',
        params: this.$route.params
      })
    }
  }
}
</script>
