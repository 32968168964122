<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-row>
        <v-col cols="12">
          <VAdvisor :advisor="deal.advisor" :key="`advisor-${deal.refid}`" :read-only="readOnly" />
        </v-col>
        <v-col cols="12">
          <VBroker :deal="deal" :key="`broker-${deal.refid}`" :read-only="readOnly" />
        </v-col>
        <v-col cols="12" v-if="$deal.isAllowed('atLeastManager')">
          <VFirm :firm="deal.firm" :key="`firm-${deal.refid}`" :read-only="readOnly" />
        </v-col>
        <v-col cols="12" v-if="$deal.isAllowed('atLeastAdvisor')">
          <VContract :deal-id="deal.id" :key="`contract-${deal.refid}`" :read-only="readOnly" />
        </v-col>
        <v-col x12>
          <VDealProperties :deal="deal" :key="`properties-${deal.refid}`" :read-only="readOnly" />
        </v-col>
        <v-col cols="12">
          <VGroupDealState :deal="deal" :key="`state-${deal.refid}`" :read-only="readOnly" />
        </v-col>
        <v-col cols="12" v-if="$deal.isAllowed('atLeastAdvisor')">
          <VDealBlocker :deal="deal" :key="`blocker-${deal.refid}`" :read-only="readOnly" />
        </v-col>
        <v-col cols="12">
          <VDealLeadInfo :deal="deal" :key="`info-${deal.refid}`" :read-only="readOnly" />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6">
      <v-row>
        <v-col cols="12">
          <VRelated :deal="deal" :key="`related-${deal.refid}`" />
        </v-col>
        <v-col cols="12">
          <VDealLoan :deal="deal" :key="`loan-${deal.refid}`" :read-only="readOnly" />
        </v-col>
        <v-col cols="12">
          <VDealDecision :deal="deal" :key="`decision-${deal.refid}`" :read-only="readOnly" />
        </v-col>
        <v-col cols="12">
          <VSubjectProperty :subject="deal.property" :key="`property-${deal.refid}`" :read-only="readOnly" />
        </v-col>
        <v-col cols="12">
          <VMortgage :mortgage="deal.mortgage" :key="`mortgage-${deal.refid}`" :read-only="readOnly" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import entityPerimeter from '@argon/evo/entities/perimeters'
import dealPerimeter from '../../perimeters'
import VDealLeadInfo from './VDealLeadInfo'
import VDealDecision from './VDealDecision'
import VDealLoan from './VDealLoan'
import VDealProperties from './VDealProperties'
import VMortgage from './VMortgage'
import VGroupDealState from './VGroupDealState'
import VDealBlocker from './VDealBlocker'
import VFirm from './VFirm'
import VAdvisor from './VAdvisor'
import VBroker from './VBroker'
import VRelated from './VRelated'
import VSubjectProperty from './VSubjectProperty'
import { VContract } from '../../../insurance/components'

export default {
  name: 'GroupDealGeneral',
  components: {
    VDealLeadInfo,
    VDealLoan,
    VDealDecision,
    VMortgage,
    VSubjectProperty,
    VGroupDealState,
    VDealBlocker,
    VDealProperties,
    VContract,
    VAdvisor,
    VFirm,
    VBroker,
    VRelated
  },
  perimeters: [entityPerimeter, dealPerimeter],
  props: {
    deal: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  methods: {}
}
</script>
