<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.blockers') }}</template>
    <template v-slot:header-actions v-if="!readOnly">
      <v-tooltip top transition="fade-transition" v-if="blockers.length > 0">
        <template v-slot:activator="{ on }">
          <v-btn icon small @click="create" v-on="on">
            <v-icon small v-text="$vuetify.icons.values.reset" />
          </v-btn>
        </template>
        <span>{{ $t('labels.reset') }}</span>
      </v-tooltip>
      <v-tooltip top transition="fade-transition" v-else>
        <template v-slot:activator="{ on }">
          <v-btn icon small @click="create" v-on="on">
            <v-icon small v-text="$vuetify.icons.values.add" />
          </v-btn>
        </template>
        <span>{{ $t('labels.create') }}</span>
      </v-tooltip>
    </template>
    <template v-slot:content>
      <v-data-table :headers="headers" :items="blockers" item-key="name" :loading="loading" hide-default-footer>
        <template v-slot:[`item.blocker`]="{ item }">
          {{ item.display }}
        </template>
        <template v-slot:[`item.run_on`]="{ item }">
          {{ transformDate(item.run_on) }}
        </template>
        <template v-slot:[`item.actions`]="{}">
          <v-btn small icon @click="destroy">
            <v-icon small>{{ $vuetify.icons.values.trash }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </template>
  </VWidget>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { formatDT } from '@argon/app/helpers'
import { VWidget } from '@argon/app/components'
import { NAMESPACE } from '@argon/evo/boards/store'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { NAMESPACE as BLOCKER_NAMESPACE } from '../../../blockers/store'

export default {
  name: 'VDealBlocker',
  components: { VWidget },
  props: {
    deal: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  data() {
    return {
      loading: true,
      headers: [
        { text: this.$t('labels.name'), sortable: false, value: 'blocker', align: 'left' },
        { text: this.$t('labels.scheduledFor'), sortable: false, value: 'run_on', align: 'left' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ]
    }
  },
  computed: {
    ...mapState(BLOCKER_NAMESPACE, ['blockers', 'presets']),
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale'])
  },
  methods: {
    ...mapActions(BLOCKER_NAMESPACE, ['listBlockers', 'deleteBlocker']),
    create() {
      this.$router.push({
        name: 'nestedDealBlocker',
        params: { ...this.$route.params, blockerId: 'new' }
      })
    },
    destroy() {
      this.deleteBlocker(this.deal.id)
    },
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL', tz: 'UTC', locale: this.locale }) : ''
    }
  },
  created() {
    this.listBlockers(this.deal.id).then(() => {
      this.loading = false
    })
  }
}
</script>
