<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.loan') }}</template>
    <template v-slot:header-actions v-if="!readOnly">
      <v-btn icon small @click="edit">
        <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.fundingDate') }}</v-list-item-subtitle>
            <v-list-item-title>{{ transformDate(deal.funding_date) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.loanAmount') }}</v-list-item-subtitle>
            <v-list-item-title class="error--text" v-if="deal.lead_loan_mismatch">
              {{ toCurrency(deal.loan) }}
              <span>({{ toCurrency(deal.lead_loan) }})</span>
            </v-list-item-title>
            <v-list-item-title v-else> {{ toCurrency(deal.loan) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.monthlyPayment') }}</v-list-item-subtitle>
            <v-list-item-title class="error--text" v-if="deal.lead_monthly_payment_mismatch">
              {{ toCurrency(deal.monthly_payment) }}
              <span>({{ toCurrency(deal.lead_monthly_payment) }})</span>
            </v-list-item-title>
            <v-list-item-title v-else>{{ toCurrency(deal.monthly_payment) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { formatDT } from '@argon/app/helpers'
import { VWidget } from '@argon/app/components'
import { money } from '../../../utils'

export default {
  name: 'VDealLoan',
  components: { VWidget },
  props: {
    deal: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale'])
  },
  methods: {
    toCurrency(v) {
      return money(v, this.locale)
    },
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL', tz: 'UTC', locale: this.locale }) : '' // We need the original date from the server
    },
    edit() {
      this.$router.push({
        name: 'nestedLoanAmounts',
        params: this.$route.params
      })
    }
  }
}
</script>
