<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.contractNumber') }}</template>
    <template v-slot:header-actions v-if="canEdit">
      <v-btn icon small @click="edit">
        <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ deal.meta.contract_number }}</v-list-item-title>
            <v-list-item-subtitle>{{ insurerName }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { VWidget } from '@argon/app/components'
import dealPerimeter from '../../perimeters'
import { NAMESPACE as IN_NAMESPACE } from '@/insurance/store'

export default {
  name: 'VContractNumber',
  perimeters: [dealPerimeter],
  components: { VWidget },
  props: {
    deal: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    ...mapState(IN_NAMESPACE, ['insurers']),
    canEdit() {
      return this.readOnly ? false : this.$deal.isAllowed('atLeastAdvisor')
    },
    insurer() {
      return this.insurers.find((x) => this.deal.insurer === x.value)
    },
    insurerName() {
      return this.insurer ? this.insurer.name : null
    }
  },
  methods: {
    edit() {
      this.$router.push({
        name: 'nestedContractNumber',
        params: { ...this.$route.params }
      })
    }
  }
}
</script>
