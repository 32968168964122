<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-row>
        <v-col cols="12">
          <VAdvisor :advisor="deal.advisor" :key="`advisor-${deal.refid}`" />
        </v-col>
        <v-col cols="12" v-if="$deal.isAllowed('atLeastManager')">
          <VCommissioning :deal="deal" :key="`commissioning-${deal.refid}`" :read-only="readOnly" />
        </v-col>
        <v-col cols="12">
          <VBroker :deal="deal" :key="`broker-${deal.refid}`" />
        </v-col>
        <v-col cols="12" v-if="$deal.isAllowed('atLeastManager')">
          <VFirm :firm="deal.firm" :key="`firm-${deal.refid}`" :read-only="readOnly" />
        </v-col>

        <v-col cols="12">
          <VContract-number :deal="deal" :key="`contract-${deal.refid}`" :read-only="readOnly" />
        </v-col>
        <v-col cols="12">
          <VIndividualDealState :deal="deal" :key="`state-${deal.refid}`" :read-only="readOnly" />
        </v-col>
        <v-col cols="12" v-if="parentGroupDeal">
          <VDealLeadInfo :deal="parentGroupDeal" :key="`info-${parentGroupDeal.refid}`" />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6">
      <v-row>
        <v-col cols="12">
          <VRelated :deal="deal" :key="`related-${deal.refid}`" />
        </v-col>
        <v-col cols="12">
          <VDealProperties :deal="deal" :key="`properties-${deal.refid}`" />
        </v-col>
        <v-col cols="12" v-if="$deal.isAllowed('atLeastAdvisor')">
          <VDealBlocker :deal="deal" :key="`blocker-${deal.refid}`" :read-only="readOnly" />
        </v-col>
        <v-col cols="12" v-if="parentGroupDeal">
          <VDealLoan :deal="parentGroupDeal" :key="`loan-${parentGroupDeal.refid}`" read-only />
        </v-col>
        <v-col cols="12" v-if="parentGroupDeal">
          <VDealDecision :deal="parentGroupDeal" :key="`decision-${parentGroupDeal.refid}`" />
        </v-col>
        <v-col cols="12" v-if="parentGroupDeal">
          <VSubjectProperty :subject="parentGroupDeal.property" :key="`property-${parentGroupDeal.refid}`" />
        </v-col>
        <v-col cols="12" v-if="parentGroupDeal">
          <VMortgage :mortgage="parentGroupDeal.mortgage" :key="`mortgage-${parentGroupDeal.refid}`" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import entityPerimeter from '@argon/evo/entities/perimeters'
import dealPerimeter from '../../perimeters'
import VDealLeadInfo from './VDealLeadInfo'
import VDealDecision from './VDealDecision'
import VDealLoan from './VDealLoan'
import VMortgage from './VMortgage'
import VSubjectProperty from './VSubjectProperty'
import VDealProperties from './VDealProperties'
import VIndividualDealState from './VIndividualDealState'
import VDealBlocker from './VDealBlocker'
import VAdvisor from './VAdvisor'
import VFirm from './VFirm'
import VBroker from './VBroker'
import VRelated from './VRelated'
import VContractNumber from './VContractNumber'
import VCommissioning from './VCommissioning'
import { NAMESPACE } from '../../store'

export default {
  name: 'IndividualDealGeneral',
  components: {
    VIndividualDealState,
    VDealLeadInfo,
    VDealLoan,
    VDealDecision,
    VMortgage,
    VSubjectProperty,
    VDealBlocker,
    VDealProperties,
    VAdvisor,
    VFirm,
    VBroker,
    VContractNumber,
    VCommissioning,
    VRelated
  },
  perimeters: [entityPerimeter, dealPerimeter],
  props: {
    deal: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  data: () => ({
    parentGroupDeal: null
  }),
  methods: {
    ...mapActions(NAMESPACE, ['getDeal'])
  },
  created() {
    if (this.deal.parent_id) {
      this.getDeal({ dealId: this.deal.parent_id }).then((parentDeal) => {
        if (parentDeal.is_group) {
          this.parentGroupDeal = parentDeal
        }
      })
    }
  }
}
</script>
