<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.property') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.streetAddress') }}</v-list-item-subtitle>
            <v-list-item-title>{{ subject.address.street_address }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.province') }}</v-list-item-subtitle>
            <v-list-item-title>{{ $t(`regions.CA.${subject.address.province}`) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.city') }}</v-list-item-subtitle>
            <v-list-item-title>{{ subject.address.city }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.postalCode') }}</v-list-item-subtitle>
            <v-list-item-title>{{ subject.address.postal_code }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.countryCode') }}</v-list-item-subtitle>
            <v-list-item-title>{{ subject.address.country_code }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'

export default {
  name: 'VSubjectProperty',
  components: { VWidget },
  props: {
    subject: { type: Object, required: true }
  }
}
</script>
