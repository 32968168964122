var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VWidget',{attrs:{"content-class":"pa-0"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('labels.blockers')))]},proxy:true},(!_vm.readOnly)?{key:"header-actions",fn:function(){return [(_vm.blockers.length > 0)?_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":_vm.create}},on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.$vuetify.icons.values.reset)}})],1)]}}],null,false,4100443059)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.reset')))])]):_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":_vm.create}},on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.$vuetify.icons.values.add)}})],1)]}}],null,false,1421508199)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.create')))])])]},proxy:true}:null,{key:"content",fn:function(){return [_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.blockers,"item-key":"name","loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.blocker",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.display)+" ")]}},{key:"item.run_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.transformDate(item.run_on))+" ")]}},{key:"item.actions",fn:function(ref){return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":_vm.destroy}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.trash))])],1)]}}],null,true)})]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }