<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-row>
        <v-col cols="12">
          <VBroker :deal="deal" :key="`broker-${deal.refid}`" />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6">
      <v-row>
        <v-col x12>
          <VDealProperties :deal="deal" :key="`properties-${deal.refid}`" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import entityPerimeter from '@argon/evo/entities/perimeters'
import dealPerimeter from '../../perimeters'
import VDealProperties from './VDealProperties'
import VBroker from './VBroker'

export default {
  name: 'HomeDealGeneral',
  components: {
    VDealProperties,
    VBroker
  },
  perimeters: [entityPerimeter, dealPerimeter],
  props: {
    deal: { type: Object, required: true }
  },
  methods: {}
}
</script>
