<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.commissioning') }}</template>
    <template v-slot:header-actions v-if="canEdit">
      <v-btn icon small @click="edit">
        <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-title>{{ $t(`labels.${deal.meta.commissioning}`) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'
import dealPerimeter from '../../perimeters'

export default {
  name: 'VCommissioning',
  perimeters: [dealPerimeter],
  components: { VWidget },
  props: {
    deal: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    canEdit() {
      return this.readOnly ? false : this.$deal.isAllowed('atLeastManager')
    }
  },
  methods: {
    edit() {
      this.$router.push({
        name: 'nestedCommissioning',
        params: { ...this.$route.params }
      })
    }
  }
}
</script>
