<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.properties') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.refnum') }}</v-list-item-subtitle>
            <v-list-item-title>{{ deal.refnum }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.createdOn') }}</v-list-item-subtitle>
            <v-list-item-title>{{ transformDate(deal.created_on) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.mortgageAgency') }}</v-list-item-subtitle>
            <v-list-item-title>{{ deal.firm ? deal.firm.display : '' }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-lead-list-item :lead-id="deal.lead_id" v-if="deal.lead_id" :key="`lead-${deal.refid}`" />
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { formatDT } from '@argon/app/helpers'
import { VWidget } from '@argon/app/components'
import VRelatedListItem from './VRelatedListItem'
import VLeadListItem from './VLeadListItem'

export default {
  name: 'VDealProperties',
  components: { VWidget, VRelatedListItem, VLeadListItem },
  props: {
    deal: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale'])
  },
  methods: {
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL', tz: this.timezone, locale: this.locale }) : ''
    }
  }
}
</script>
