<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.relations') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-related-list-item :deal="deal.parent_info" v-if="deal.parent_id" :key="`parent-${deal.refid}`" />
        <template v-for="item in deal.children_info">
          <v-divider :key="`divider-related-${item.id}`" />
          <v-related-list-item :deal="item" :key="`related-${item.id}`" child />
        </template>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'
import VRelatedListItem from './VRelatedListItem'
export default {
  name: 'VRelated',
  components: { VWidget, VRelatedListItem },
  props: {
    deal: { type: Object, required: true }
  }
}
</script>

<style></style>
