<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.broker') }}</template>
    <template v-slot:content>
      <v-list class="mb-1">
        <v-list-item class="py-1">
          <v-list-item-title>{{ $t('noBroker') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'

export default {
  name: 'VNoBroker',
  components: { VWidget }
}
</script>
<i18n>
{
    "en": {
        "noBroker": "No broker defined"
    }, 
    "fr": {
        "noBroker": "Aucun référent défini"
    }
}
</i18n>
