<template>
  <v-list-item v-if="lead">
    <v-list-item-content>
      <v-list-item-subtitle>{{ $t(`labels.${label}`) }}</v-list-item-subtitle>
      <v-list-item-title>{{ lead.provider_lead_id }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="$deal.isAllowed('atLeastManager')">
      <v-btn icon small :to="goToLead()">
        <v-icon small>{{ $vuetify.icons.values.view }}</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { NAMESPACE } from '../../store'
import dealPerimeter from '../../perimeters'

export default {
  name: 'VLeadListItem',
  perimeters: [dealPerimeter],
  data: () => ({ loading: true, lead: null }),
  props: {
    label: { type: String, default: 'lead' },
    leadId: { type: [String, Number], required: true }
  },
  methods: {
    ...mapActions(NAMESPACE, ['getLead']),
    goToLead() {
      return {
        path: `/leads/${this.lead.board.refname}/cards/${this.lead.id}`
      }
    }
  },
  created() {
    this.getLead(this.leadId)
      .then((lead) => {
        this.lead = lead
      })
      .catch(() => {})
      .finally(() => {
        this.loading = false
      })
  }
}
</script>
