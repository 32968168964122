<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.dealDecisionState') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.insurerDecision') }}</v-list-item-subtitle>
            <v-list-item-title>{{ $t(`labels.${deal.insurer_status}`) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.dealLastUpdate') }}</v-list-item-subtitle>
            <v-list-item-title>{{ transformDate(deal.updated_on) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { formatDT } from '@argon/app/helpers'
import { VWidget } from '@argon/app/components'

export default {
  name: 'VDealDecision',
  components: { VWidget },
  props: {
    deal: Object
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale'])
  },
  methods: {
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL', tz: this.timezone, locale: this.locale }) : ''
    }
  }
}
</script>
