<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('title') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('mortgageType') }}</v-list-item-subtitle>
            <v-list-item-title>{{ mortgage.mortgage_type }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('loanType') }}</v-list-item-subtitle>
            <v-list-item-title>{{ mortgage.loan_type }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('actualPaymentTerm') }}</v-list-item-subtitle>
            <v-list-item-title>{{ mortgage.payment_term }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('amortizationTerm') }}</v-list-item-subtitle>
            <v-list-item-title>{{ mortgage.amortization_term }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('totalLoanTerm') }}</v-list-item-subtitle>
            <v-list-item-title>{{ toCurrency(mortgage.total_loan_amount) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('interestType') }}</v-list-item-subtitle>
            <v-list-item-title>{{ mortgage.interest_type }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('paymentTerm') }}</v-list-item-subtitle>
            <v-list-item-title>{{ mortgage.payment_term }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('maturityDate') }}</v-list-item-subtitle>
            <v-list-item-title>{{ transformDate(mortgage.maturity_date) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('netLoanAmount') }}</v-list-item-subtitle>
            <v-list-item-title>{{ toCurrency(mortgage.net_loan_amount) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('paymentFrequency') }}</v-list-item-subtitle>
            <v-list-item-title>{{ mortgage.payment_frequency }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { formatDT } from '@argon/app/helpers'
import { VWidget } from '@argon/app/components'
import { money } from '../../../utils'

export default {
  name: 'VMortgage',
  components: { VWidget },
  props: {
    mortgage: { type: Object, required: true }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale'])
  },
  methods: {
    toCurrency(v) {
      return money(v, this.locale)
    },
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL', tz: 'UTC', locale: this.locale }) : '' // We need the original date from the server
    }
  }
}
</script>

<i18n>
{
  "en": {
    "actualPaymentTerm": "@:labels.actualPaymentTerm",
    "amortizationTerm": "@:labels.amortizationTerm",
    "interestType": "@:labels.interestType",
    "loanType": "@:labels.loanType",
    "maturityDate": "@:labels.maturityDate",
    "mortgageType": "@:labels.mortgageType",
    "netLoanAmount": "@:labels.netLoanAmount",
    "paymentFrequency": "@:labels.paymentFrequency",
    "paymentTerm": "@:labels.paymentTerm",
    "title": "@:labels.mortgage",
    "totalLoanTerm": "@:labels.totalLoanTerm"
  },
  "fr": {
    "actualPaymentTerm": "@:labels.actualPaymentTerm",
    "amortizationTerm": "@:labels.amortizationTerm",
    "interestType": "@:labels.interestType",
    "loanType": "@:labels.loanType",
    "maturityDate": "@:labels.maturityDate",
    "mortgageType": "@:labels.mortgageType",
    "netLoanAmount": "@:labels.netLoanAmount",
    "paymentFrequency": "@:labels.paymentFrequency",
    "paymentTerm": "@:labels.paymentTerm",
    "title": "@:labels.mortgage",
    "totalLoanTerm": "@:labels.totalLoanTerm"
  }
}
</i18n>
