<template>
  <v-list-item v-if="deal">
    <v-list-item-content>
      <v-list-item-subtitle>{{ child ? $t('childDeal') : $t('parentDeal') }}</v-list-item-subtitle>
      <v-list-item-title>{{ deal.refnum }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn icon small :to="goToDeal()">
        <v-icon small>{{ $vuetify.icons.values.view }}</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'VRelatedListItem',
  props: {
    deal: { type: Object, required: true },
    child: { type: Boolean, default: false }
  },
  methods: {
    goToDeal() {
      return {
        path: `/deals/${this.deal.deal_type}/cards/${this.deal.id}`
      }
    }
  }
}
</script>
<i18n>
{
  "en": {
    "parentDeal": "Original Deal",
    "childDeal": "Copy Deal"
  },
  "fr": {
    "parentDeal": "Dossier source",
    "childDeal": "Dossier copie"
  }
}
</i18n>
