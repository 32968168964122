<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.broker') }}</template>
    <template v-slot:header-actions v-if="canEdit">
      <v-btn icon small @click="edit">
        <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.displayName') }}</v-list-item-subtitle>
            <v-list-item-title>{{ brokerDisplay }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.email') }}</v-list-item-subtitle>
            <v-list-item-title>{{ brokerEmail }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="deal.lead_broker_id_mismatch" />
        <VBrokerListItem v-if="showPrevious" :brokerId="deal.lead_broker_id" label="originalBroker" />
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'
import dealPerimeter from '../../perimeters'
import { VBrokerListItem } from '../../../brokers/components'

export default {
  name: 'VBroker',
  perimeters: [dealPerimeter],
  components: { VWidget, VBrokerListItem },
  props: {
    deal: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    brokerDisplay() {
      return this.deal.broker ? this.deal.broker.display : ''
    },
    brokerEmail() {
      return this.deal.broker && this.deal.broker.contact ? this.deal.broker.contact.emails[0].address : ''
    },
    canEdit() {
      return !this.readOnly && this.$deal.isAllowed('manage')
    },
    showPrevious() {
      return this.deal.broker && this.deal.lead_broker_id_ismatch
    }
  },
  methods: {
    edit() {
      this.$router.push({
        name: 'nestedBroker',
        params: { ...this.$route.params, brokerId: this.deal.broker_id || 'new' }
      })
    }
  }
}
</script>
