<template>
  <VWidget content-class="pa-0" :loading="loading">
    <template v-slot:title>{{ $t('labels.broker') }}</template>
    <template v-slot:content v-if="!loading">
      <v-list class="mb-1">
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.firm') }}</v-list-item-subtitle>
            <v-list-item-title>{{ broker.firm.display }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.name') }}</v-list-item-subtitle>
            <v-list-item-title>{{ broker.display }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.email') }}</v-list-item-subtitle>
            <v-list-item-title>{{ email }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.phone') }}</v-list-item-subtitle>
            <v-list-item-title>{{ phone }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { NAMESPACE } from '../store'
import { VWidget } from '@argon/app/components'

export default {
  name: 'VFullBroker',
  components: { VWidget },
  data: () => ({ loading: true }),
  props: {
    brokerId: { type: [String, Number], required: true }
  },
  computed: {
    ...mapState(NAMESPACE, ['broker']),
    email() {
      return this.broker.contact.emails.length > 0 ? this.broker.contact.emails[0].address : null
    },
    phone() {
      return this.broker.contact.phones.length > 0 ? this.broker.contact.phones[0].number : null
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['getBroker'])
  },
  created() {
    this.getBroker(this.brokerId).finally(() => {
      this.loading = false
    })
  }
}
</script>
