<template>
  <v-autocomplete
    v-model="localValue"
    :items="brokers"
    :loading="loading"
    :search-input.sync="filterString"
    item-text="user.display"
    item-value="user_id"
    hide-no-data
    :label="$t('broker')"
    :required="required"
    :error-messages="errors"
    :disabled="readonly"
  >
  </v-autocomplete>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { LIKE_OPERATOR } from '@argon/app/axios'
import { CustomFieldMixin } from '@argon/app/mixins'
import { NAMESPACE } from '../store'

export default {
  name: 'BrokerField',
  mixins: [CustomFieldMixin],
  props: {
    readonly: { type: Boolean, default: false }
  },
  data: () => ({
    filterString: null,
    loading: false
  }),
  computed: {
    ...mapState(NAMESPACE, ['brokers'])
  },
  watch: {
    filterString: function (v) {
      if (v && v.length > 2) {
        this.doSearch()
      }
    },
    disabled: function (v) {
      this.filterString = null
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['listBrokers']),
    doSearch() {
      this.loading = true
      this.listBrokers({ filters: `__any__:${LIKE_OPERATOR}:${this.filterString}` })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    }
  },
  created() {
    if (this.value) {
      this.listBrokers({ filters: `user_id:${LIKE_OPERATOR}:${this.value}` })
    } else {
      this.listBrokers({ pager: { page: 1, per_page: 5 } })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "broker": "@:labels.broker"
  },
  "fr": {
    "broker": "@:labels.broker"
  }
}
</i18n>
