<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.advisor') }}</template>
    <template v-slot:header-actions v-if="canEdit">
      <v-btn icon small @click="edit">
        <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content v-if="advisor">
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.displayName') }}</v-list-item-subtitle>
            <v-list-item-title>{{ advisorLabel }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'
import dealPerimeter from '../../perimeters'

export default {
  name: 'VAdvisor',
  perimeters: [dealPerimeter],
  components: { VWidget },
  props: {
    advisor: { type: Object },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    advisorLabel() {
      return this.advisor ? this.advisor.display : ''
    },
    canEdit() {
      return !this.readOnly && this.$deal.isAllowed('manage')
    }
  },
  methods: {
    edit() {
      this.$router.push({
        name: 'nestedAdvisor',
        params: { ...this.$route.params, advisorId: this.advisor.id || 'new' }
      })
    }
  }
}
</script>
