<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.firm') }}</template>
    <template v-slot:header-actions v-if="canEdit">
      <v-btn icon small @click="edit">
        <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content v-if="firm">
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.displayName') }}</v-list-item-subtitle>
            <v-list-item-title>{{ firmLabel }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'
import dealPerimeter from '../../perimeters'

export default {
  name: 'VFirm',
  perimeters: [dealPerimeter],
  components: { VWidget },
  props: {
    firm: { type: Object },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    firmLabel() {
      return this.firm ? this.firm.display : ''
    },
    canEdit() {
      return !this.readOnly && this.$deal.isAllowed('manage')
    }
  },
  methods: {
    edit() {
      this.$router.push({
        name: 'nestedFirm',
        params: { ...this.$route.params, firmCode: this.firm.firm_code || 'new' }
      })
    }
  }
}
</script>
